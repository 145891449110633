import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  navBar,
  mainBody,
  boutique,
  design,
  getInTouch,
} from "./editable-stuff/config.js";
import MainBody from "./components/home/MainBody";
import Boutique from "./components/home/Boutique";
import Design from "./components/home/Design";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
// import { Blog } from "./components/blog/Blog";
// import BlogPost from "./components/blog/BlogPost";
import GetInTouch from "./components/home/GetInTouch.jsx";

const Home = React.forwardRef((props, ref) => {
  return (
    <>
      <MainBody
        gradient={mainBody.gradientColors}
        icons1={mainBody.icons1}
        icons2={mainBody.icons2}
        message={mainBody.message}
        imageLink1={mainBody.imageLink1}
        imageLink2={mainBody.imageLink2}
        imgSize={mainBody.imageSize}
        ref={ref}
      />
      {design.show && (
        <Design
          heading={design.heading}
          message={design.message}
          link={design.imageLink}
          imgSize={design.imageSize}
          imageSizeSmall={design.imageSizeSmall}
          icons={design.icons}
        />
      )}


      {boutique.show && (
        <Boutique
          heading={boutique.heading}
          message={boutique.message}
          link={boutique.imageLink}
          imgSize={boutique.imageSize}
          icons={boutique.icons}
        />
      )}

      {getInTouch.show && (
        <GetInTouch
          heading={getInTouch.heading}
          message={getInTouch.message}
          email={getInTouch.email}
        />
      )}


    </>
  );
});

const App = () => {
  const titleRef = React.useRef();

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL + "/"}>
      {navBar.show && <Navbar ref={titleRef} />}
      <Routes>
        <Route path="/" exact element={<Home ref={titleRef} />} />
      </Routes>
      {/* {false && <Route path="/blog" exact component={Blog} />}
      {false && <Route path="/blog/:id" component={BlogPost} />} */}
      <Footer />
    </BrowserRouter>
  );
};

export default App;
