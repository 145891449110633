import React from "react";
import Container from "react-bootstrap/Container";
import Typist from 'react-typist-component';
import { Jumbotron } from "./migration";
import Card from "react-bootstrap/Card";

const MainBody = React.forwardRef(
  ({ gradient, icons1, icons2, imageLink1, imageLink2, message, imgSize }, ref) => {
    return (
      <Jumbotron
        fluid
        id="home"
        style={{
          background: `linear-gradient(136deg,${gradient})`,
          // backgroundSize: "1200% 1200%",
        }}
        className="title bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div id="stars"></div>
        <Container className="text-center">
          <div className="row">

            <span className="col-md-6"
              ref={ref}
            >
              <Card.Link href="#design">
                <Card className="card cardImage shadow-lg p-4 mb-5">
                  <Card.Body>
                    <img
                      class="rounded-circle d-none d-lg-inline "
                      src={imageLink2}
                      alt="design logo"
                      width={imgSize}
                      height={imgSize}
                    />
                    <img
                      class="rounded-circle d-lg-none d-inline"
                      src={imageLink2}
                      alt="design logo"
                      width={imgSize / 1.4}
                      height={imgSize / 1.4}
                    />
                    <span className="col-6" >
                      <div className="p-5">
                        {icons2.map((icon, index) => (
                          <a
                            key={`social-icon-${index}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={icon.url}
                            aria-label={`My ${icon.image.split("-")[1]}`}
                          >

                            {
                              icon.image.length === 0
                                ? <i className={`fab ${icon.image}  fa-2x socialicons`}><img className={`${icon.className}`} src={`${icon.imageLink}`} alt={`${icon.alt}`} /></i>
                                : <i className={`fab ${icon.image}  fa-2x socialicons`} />
                            }
                          </a>
                        ))}
                      </div>
                    </span>
                  </Card.Body>
                </Card>
              </Card.Link>
            </span>

            <span className="col-md-6">
              <Card.Link href="#boutique">
                <Card className="card cardImage shadow-lg p-4 mb-5">
                  <Card.Body>
                    <img
                      class="rounded-circle d-none d-lg-inline"
                      src={imageLink1}
                      alt="boutique logo"
                      width={imgSize}
                      height={imgSize}
                    />
                    <img
                      class="rounded-circle d-lg-none d-inline "
                      src={imageLink1}
                      alt="boutique logo"
                      width={imgSize / 1.4}
                      height={imgSize / 1.4}
                    />
                    <span className="col-6">
                      <div className="p-5">
                        {icons1.map((icon, index) => (
                          <a
                            key={`social-icon-${index}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={icon.url}
                            aria-label={`My ${icon.image.split("-")[1]}`}
                          >
                            <i className={`fab ${icon.image}  fa-2x socialicons`} />
                          </a>
                        ))}
                      </div>
                    </span>
                  </Card.Body>
                </Card>

              </Card.Link>
            </span>

          </div>


          <Typist>
            <div className="lead typist">
              {message}
            </div>
          </Typist>

          <div className="row">



          </div>
        </Container>
      </Jumbotron >
    );
  }
);

export default MainBody;
