import React from "react";

import { Jumbotron } from "./migration";

const Design = ({ heading, message, link, imgSize, icons }) => {
  return (
    <Jumbotron id="design" className="m-0">
      <div className="row p-2">
        <div className="col-5 d-none d-lg-block align-self-center">
          <img
            className="rounded-circle"
            src={link}
            alt="profilepicture"
            width={imgSize}
            height={imgSize}
          />
        </div>
        <div className="col-12 d-lg-none d-block text-center pb-4">
          <img
            className="rounded-circle"
            src={link}
            alt="profilepicture"
            width={imgSize / 2}
            height={imgSize / 2}
          />
        </div>
        <div className="col-lg-7">
          <h2 className="display-4 mb-5 text-center d-none d-lg-block">{heading}</h2>
          <p className="lead text-center">{message}</p>
          <div className="row">
            <div className="text-center">
              {icons.map((icon, index) => (
                <a
                  key={`social-icon-${index}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={icon.url}
                  aria-label={`My ${icon.image.split("-")[1]}`}
                >
                  {
                    icon.image.length === 0
                      ? <i className={`fab ${icon.image}  fa-2x socialicons`}><img className={`${icon.className}`} src={`${icon.imageLink}`} alt={`${icon.alt}`} /></i>
                      : <i className={`fab ${icon.image}  fa-2x socialiconsblack`} />
                  }
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Jumbotron >
  );
};

export default Design;
